import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Enums from 'src/app/enums';


@Injectable({
    providedIn: 'root'
})
export class DashboardsService {

    constructor(
        private http: HttpClient,
        public httpParams: HttpParams
    ) { }

    private headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        api: 'true',
    };

    /**
     * 
     *@param body : { 
            "dt_inicio": "2024-01-01",
            "dt_fim": "2024-05-01",
            "cd_status": "",
            "id_tp_situacao_contrato": "",
        }
     * @returns Observable
     */
    getInventoryDashboardData(body: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/Dashboards/inventoryDashboardData', body, { headers: headers });
    }

    /**
     * 
     *@param body : { 
            "dt_inicio": "2024-01-01",
            "dt_fim": "2024-05-01",
            "cd_status": "",
            "id_tp_situacao_contrato": "",
        }
     * @returns Observable
     */
    getProductivityDashboardData(body: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/Dashboards/getProductivityDashboardData', body, { headers: headers });
    }

    /**
     * 
     *@param body : { 
            "dt_inicio": "2024-01-01",
            "dt_fim": "2024-05-01",
            "cd_status": "",
            "id_tp_situacao_contrato": "",
        }
     * @returns Observable
     */
    getInventoryDashboardDataForManager(body: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/Dashboards/inventoryDashboardDataForManager', body, { headers: headers });
    }

    /**
     * 
     *@param body : { 
            "dt_inicio": "2024-01-01",
            "dt_fim": "2024-05-01",
            "cd_status": "",
            "id_tp_situacao_contrato": "",
        }
     * @returns Observable
     */
    getProductivityDashboardDataForManager(body: any) {
        var headers = new HttpHeaders(this.headers);
        return this.http.post(Enums.VARS.API_BASE_URL + 'guard/Dashboards/getProductivityDashboardDataForManager', body, { headers: headers });
    }

}
