import { MenuItem } from './menu.model';



export const MENU: MenuItem[] = [
  {
    id: 1,
    label: '',
    isTitle: true,
    permission: false
  },
  {
    id: 5,
    label: 'DASHBOARD',
    collapseid: 'dash',
    icon: 'ri-dashboard-2-line',
    permission: false,
    subItems: [
      {
        id: 5.1,
        label: 'HOME',
        collapseid: 'dash',
        icon: 'ri-dashboard-2-line',
        link: '/',
        permission: false,
        parentId: 5
      }
    ]
  },
  {
    id: 10,
    label: 'APVS ATENDE',
    collapseid: 'atende',
    icon: 'ri-customer-service-2-fill',
    permission: 'apvs_atende',
    subItems: [
      {
        id: 56,
        label: 'CONSULTA',
        icon: 'ri-account-circle-line',
        permission: 'business_query_consultant',
        parentId: 10,
        subItems: [
          {
            id: 56.1,
            label: 'REGIONAL/CONSULTOR',
            parentId: 56,
            link: '/business-registers',
            permission: 'business_query_consultant',
          },          
        ]
      },
      {
        id: 55,
        label: 'ASSOCIADOS',
        icon: 'ri-account-circle-line',
        permission: 'associates',
        parentId: 10,
        subItems: [
          {
            id: 55.1,
            label: 'CADASTRADOS',
            parentId: 55,
            link: '/tables/users',
            permission: 'associates',
          },
          {
            id: 55.2,
            label: 'GERAL',
            parentId: 55,
            link: '/associates',
            permission: 'associates'
          },
          
        ]
      },
      {
        id: 9,
        label: 'CANCELAMENTOS',
        permission: 'cacelations',
        icon: 'ri-close-circle-fill',
        parentId: 10,
        subItems: [
          {
            id: 9.1,
            label: 'LISTAR',
            permission: 'cacelations',
            link: '/cancellations'
          },
          {
            id: 9.2,
            label: 'RELATÓRIOS',
            permission: 'cancelation_reports',
            link: '/cancellations/reports'
          },
          {
            id: 9.3,
            label: 'GRÁFICOS',
            permission: 'cancalation_graphics',
            link: '/cancellations/graphics'
          }
        ]
      },
      {
        id: 11,
        label: 'ENDEREÇOS',
        permission: 'address',
        icon: 'ri-close-circle-fill',
        parentId: 10,
        subItems: [
          {
            id: 11.1,
            label: 'LISTAR',
            permission: 'address',
            link: '/addresses'
          }
        ]
      },
      {
        id: 10.1,
        label: 'EVENTOS DE TERCEIRO',
        permission: 'third_events',
        icon: 'ri-road-map-fill',
        link: '/third-events',
        parentId: 10,
        subItems: [
    
        ]
      },
      {
        id: 10.2,
        label: 'CONSULTORES',
        permission: 'consultants',
        icon: 'ri-road-map-fill',
        parentId: 10,
        subItems: [
          {
            id: 10.21,
            label: 'LISTAR',
            permission: 'consultants',
            link: '/consultants'
          },
          {
            id: 10.22,
            label: 'RELATÓRIO',
            permission: 'consultants_reports',
            link: '/consultants/reports'
          }
        ]
      },
      {
        id: 10.3,
        label: 'ANÁLISES DE PROPOSTAS',
        permission: 'proposal_access',
        icon: 'ri-road-map-fill',
        parentId: 10,
        subItems: [
          {
            id: 10.31,
            parentId: 10.3,
            label: 'LISTAR',
            permission: 'proposals',
            link: '/proposals_analysis'
          }
        ]
      },
      {
        id: 10.4,
        label: 'VISTORIA',
        permission: 'reactivation',
        icon: 'ri-close-circle-fill',
        parentId: 10,
        subItems: [
          {
            id: 10.41,
            label: 'REATIVAÇÃO',
            permission: 'reactivation_menu',
            link: '/surveys-list'
          },
          {
            id: 10.42,
            label: 'EVENTO',
            permission: 'events_menu',
            link: '/events-inspection'
          }
        ]
      }
    ]
  },
  {
    id: 11,
    label: 'COMUNICAÇÂO',
    collapseid: 'comunic',
    icon: 'ri-mail-fill',
    permission: 'comunications',
    subItems: [
      {
        id: 11.2,
        label: 'TEMPLATES DE EMAIL',
        link: '/email-templates',
        permission: 'email_templates',
        parentId: 11
      },
      {
        id: 11.1,
        label: 'RECUSAS DE PROPOSTAS',
        icon: 'ri-dashboard-2-line',
        link: '/rejections',
        permission: 'proposal_rejects',
        parentId: 11
      },
      {
        id: 11.3,
        label: 'INFORMATIVO DE ANÁLISE',
        link: '/informative_analysis',
        permission: 'informative_list',
        parentId: 11
      },
      {
        id: 11.4,
        label: 'BOAS-VINDAS',
        link: '/welcome-apvs',
        permission: 'welcome_list',
        parentId: 11
      },
      {
        id: 11.5,
        label: 'CONSULTA E-MAIL EXTERNO',
        link: '/external-email',
        permission: 'external_email',
        parentId: 11
      },
      {
        id: 11.6,
        label: 'NOTIFICAÇÕES',
        permission: 'notification',
        icon: 'ri-close-circle-fill',
        parentId: 11,
        subItems: [
          {
            id: 11.61,
            label: 'ENVIAR',
            permission: 'queue_notification',
            link: '/notifications-new'
          },
          {
            id: 11.62,
            label: 'LOGS',
            permission: 'queue_notification_logs',
            link: '/notifications-logs'
          }
        ]
      },
      {
        id: 11.7,
        label: 'AVISO DE VENCIMENTO',
        permission: 'pre_charges_comunications',
        icon: 'ri-close-circle-fill',
        parentId: 11,
        subItems: [
          {
            id: 11.71,
            label: 'A VENCER',
            permission: 'pre_charges_comunications',
            link: '/charges',
            params: { type: 1 }
          },
          {
            id: 11.72,
            label: 'NO DIA',
            permission: 'pre_charges_comunications',
            link: '/charges',
            params: { type: 2 }
          },
          {
            id: 11.73,
            label: 'Cobrança',
            permission: 'pre_charges_comunications',
            link: '/mora-charges',
          }
        ]
      }
    ]
  },
  {
    id: 12,
    label: 'ADMINISTRAÇÃO',
    collapseid: 'adm',
    icon: 'ri-admin-fill',
    permission: 'adm',
    subItems: [
      {
        id: 12.1,
        label: 'DEPARTAMENTOS',
        permission: 'department_list',
        icon: 'ri-team-fill',
        link: '/profiles',
        parentId: 12,
        subItems: [
    
        ]
      },
      {
        id: 12.2,
        label: 'USUÁRIOS APVS',
        permission: 'users',
        icon: 'ri-team-fill',
        parentId: 12,
        link: '/users_apvs'
      },
      {
        id: 12.3,
        label: 'CHANGELOG',
        permission: 'change_logs',
        icon: 'ri-team-fill',
        parentId: 12,
        link: '/changelog'
      },
      {
        id: 12.3,
        label: 'FILA DE PROCESSAMENTO',
        permission: 'queue_jobs',
        icon: 'ri-team-fill',
        parentId: 12,
        link: '/queue-jobs'
      }
    ]
  },
  {
    id: 13,
    label: 'FINANCEIRO',
    collapseid: 'financial',
    icon: 'ri-money-dollar-circle-fill',
    permission: 'financial',
    subItems: [
      {
        id: 13.1,
        label: 'CARTÕES DE CRÉDITO',
        icon: 'ri-money-dollar-circle-fill',
        link: '/credit-cards',
        permission: 'financial_credit_cards',
        parentId: 13
      },
      {
        id: 13.1,
        label: 'PERMISSÂO - CADASTRO DE CARTÕES',
        icon: 'ri-money-dollar-circle-fill',
        link: '/subscriptions-list',
        permission: 'subscriptions_list',
        parentId: 13
      }
    ]
  },
  /*
  {
    id: 14,
    label: 'APP Associado',
    collapseid: 'associate_app',
    icon: 'ri-smartphone-fill',
    permission: 'associate_app',
    subItems: [
      {
        id: 14.5,
        label: 'Acessos do associado',
        icon: '',
        permission: 'associate_access',
        parentId: 14,
        subItems: [
          {
            id: 14.51,
            label: 'POR SITUAÇÂO DE CONTRATO',
            parentId: 14.5,
            link: '/associate-app/associate-access/situation',
            permission: 'associate_access'
          },
          {
            id: 14.52,
            label: 'POR STATUS DE CONTRATO',
            parentId: 14.5,
            link: '/associate-app/associate-access/status',
            permission: 'associate_access',
          }
          
        ]
      },
      {
        id: 14.6,
        label: 'Acessos do contrato',
        icon: '',
        permission: 'contract_access',
        parentId: 14,
        subItems: [
          {
            id: 14.61,
            label: 'POR SITUAÇÂO DE CONTRATO',
            parentId: 14.6,
            link: '/associate-app/contract-access/situation',
            permission: 'contract_access'
          },
          {
            id: 14.62,
            label: 'POR STATUS DE CONTRATO',
            parentId: 14.6,
            link: '/associate-app/contract-access/status',
            permission: 'contract_access',
          }
          
        ]
      },
      {
        id: 14.7,
        label: 'Acesso - Login e cadastro',
        icon: '',
        link: '/associate-app/app_sign_in_up_rules',
        permission: 'app_sign_in_up_rules',
        parentId: 14
      },
      {
        id: 14.1,
        label: 'Versão',
        icon: '',
        link: '/associate-app/version',
        permission: 'associate_app_build',
        parentId: 14
      },
      {
        id: 14.2,
        label: 'Documentos',
        icon: '',
        link: '/associate-app/documents',
        permission: 'documents',
        parentId: 14
      },
      {
        id: 14.3,
        label: 'Situações de contrato',
        icon: '',
        link: '/associate-app/contracts-situations',
        permission: 'contracts_situations',
        parentId: 14
      },
      {
        id: 14.4,
        label: 'Status de contrato',
        icon: '',
        link: '/associate-app/contracts-status',
        permission: 'contracts_status',
        parentId: 14
      }
      
    ]
  },
  */
  {
    id: 15,
    label: 'Configurações',
    collapseid: 'config',
    icon: 'ri-settings-4-line',
    permission: 'config',
    subItems: [
      {
        id: 15.1,
        label: 'Área do associado',
        icon: '',
        permission: 'associate_permissions',
        parentId: 15,
        subItems: [
          {
            id: 15.11,
            label: 'Cadastro',
            parentId: 15.1,
            permission: 'registrations',
            subItems: [
              {
                id: 15.111,
                label: 'Status',
                parentId: 15.11,
                link: '/associate-app/contracts-status',
                permission: 'contracts_status',
              },
              {
                id: 15.112,
                label: 'Situações',
                parentId: 15.11,
                link: '/associate-app/contracts-situations',
                permission: 'contracts_situations',
              }
            ]
          },
          {
            id: 15.12,
            label: 'Permissões',
            parentId: 15.1,
            permission: null,
            subItems: [
              {
                id: 15.121,
                label: 'Login',
                parentId: 15.12,
                link: '/associate-app/app_sign_in_up_rules',
                permission: 'app_sign_in_up_rules',
              },
              {
                id: 15.122,
                label: 'Associado',
                parentId: 15.12,
                permission: 'associate_access',
                subItems: [
                  {
                    id: 15.1221,
                    label: 'Status',
                    parentId: 15.122,
                    link: '/associate-app/associate-access/status',
                    permission: 'associate_access',
                  },
                  {
                    id: 15.1222,
                    label: 'Situação',
                    parentId: 15.122,
                    link: '/associate-app/associate-access/situation',
                    permission: 'associate_access',
                  }
                ]
              },
              {
                id: 15.123,
                label: 'Contrato',
                parentId: 15.12,
                permission: 'contract_access',
                subItems: [
                  {
                    id: 15.1231,
                    label: 'Status',
                    parentId: 15.123,
                    link: '/associate-app/contract-access/status',
                    permission: 'contract_access',
                  },
                  {
                    id: 15.1232,
                    label: 'Situação',
                    parentId: 15.123,
                    link: '/associate-app/contract-access/situation',
                    permission: 'contract_access',
                  }
                ]
              }
            ]
          },
          {
            id: 15.13,
            label: 'Versão',
            parentId: 15.1,
            link: '/associate-app/version',
            permission: 'associate_app_build'
          },
          {
            id: 15.14,
            label: 'Documentos',
            parentId: 15.1,
            link: '/associate-app/documents',
            permission: 'documents'
          }
          
        ]
      },
      {
        id: 15.2,
        label: 'ANÁLISES DE PROPOSTAS',
        permission: 'proposal_config',
        icon: 'ri-road-map-fill',
        parentId: 15,
        subItems: [
          {
            id: 15.21,
            parentId: 15.2,
            label: 'AUTOMAÇÃO',
            permission: 'proposal_automation',
            link: '/proposals_analysis/automation'
          },
          {
            id: 15.22,
            parentId: 15.2,
            label: 'REGRAS',
            permission: 'proposal_rules',
            link: '/proposals_analysis/rules'
          }
        ]
      },
    ]
  }
  
  

];
