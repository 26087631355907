import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class SystemModeService {
  // BehaviorSubject to store the mode
  private modeSubject = new BehaviorSubject<string | null>(null);
  mode$ = this.modeSubject.asObservable(); // Expose as an Observable

  constructor() {
    this.loadInitialMode();
  }

  // Load the initial mode value
  async loadInitialMode() {
    const { value } = await Preferences.get({ key: 'mode' });
    this.modeSubject.next(value);
  }

  // Set the mode and update the subject
  async setMode(newMode: string) {
    await Preferences.set({ key: 'mode', value: newMode });
    this.modeSubject.next(newMode);
  }

  // Optionally, a method to subscribe to changes
  subscribeToModeChanges(callback: (mode: string | null) => void) {
    this.mode$.subscribe(callback);
  }
}
